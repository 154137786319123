<template>
	<div>
		<b-card>

			<div class="flex">
				<p>카페24 상품 번호 검색</p>
				<input class="form-control -mt-1.5 w-1/2 ml-8 mb-4" v-model="cafe24ProductNumFilter" placeholder="카페24 상품 번호 필터" type="text"/>
			</div>
			<div class="relateProductListWrap flex mb-2">
				<p class="w-20">idx</p>
				<p class="w-80">카페24 상품 번호</p>
				<p class="w-40">등록자</p>
				<p class="w-80">등록 시간</p>
			</div>
			<div v-for="(v, i) in filterList(cafe24ProductNumFilter)"
			class="relateProductListWrap flex">
				<p class="w-20">{{ i }}</p>
				<p class="w-80">{{ v.cafe24ProductNum }}</p>
				<p class="w-40">{{ v.adminName }}</p>
				<p class="w-80">{{ v.insert_date }}</p>
				<div
					class="btn w-10 inline-block -mt-1.5"
					@click.stop.prevent="editRelateProduct(v)"
				>
					<b-icon font-scale="1" icon="pencil-square"/>
				</div>
				<div
					class="btn w-10 inline-block -mt-1.5"
					@click.stop.prevent="deleteRelateProduct(v)"
				>
					<b-icon font-scale="1" icon="trash"/>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>

import { postApi } from "libs/axios";

let ths
export default {
	data() {
		return {
			inf: [
				{ title: 'No.', model: 'idx', size: 1 },
				{ title: '작성일자', key: 'createDt', size: 3 },
				{ title: '핫트 아이디', key: 'hottId', size: 2 },
				{ title: '주문번호', key: 'orderNum', size: 2 },
				{ title: 'IDX', key: 'productGroupIdx', size: 1 },
				{ title: '파트너사', key: 'companyName', size: 2 },
				{ title: '브랜드', key: 'brandName', size: 2 },
				{ title: '상품명', key: 'productGroupName', size: 3, notCenter: 1 },
				{ title: '옵션명', key: 'productOptionName', size: 2, notCenter: 1 },
				{ title: '별점', key: 'reviewScore', size: 1 },
				{ title: '도움 수', key: 'likeCount', size: 1 },
				{ title: '상품 리뷰', key: 'reviewContent', size: 6, notCenter: 1 },
				{ title: '리뷰<br>글자수', key: 'reviewContentCount', size: 2 },
				{ title: '추가 의견', key: 'additionalComment', size: 3, notCenter: 1 },
				{ title: '의견<br>글자수', key: 'additionalCommentCount', size: 2 },
				{ title: '신고접수', key: 'claimCount', size: 1 },
				{ title: '신고 사유', key: 'reasonType', size: 2 },
			],
			lastOpt: {},
			res: [],
			cafe24ProductNumFilter: '',
		}
	},
	methods: {
		editRelateProduct(v) {
			page('relateProductAdd', { relateProductSeq: v.cafe24ProductNum} )
		},
		deleteRelateProduct( v ) {
			postApi( 'naver/deleteRelateProduct', { cafe24ProductNum: v.cafe24ProductNum } ).then( v => {
				alert.s( '삭제 완료되었습니다' )
				refresh()
			} )
		},
		filterList() {
			return this.res.filter(v=>(v.cafe24ProductNum+'').indexOf(this.cafe24ProductNumFilter) != -1)
		}
	},
	created() {
		ths = this
		postApi('naver/selectRelateProductAll').then(v=>{
			this.res = v
		});
	},
}
</script>
